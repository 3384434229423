import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { buyer as actions } from '@store/actions';
import { buyer as services } from '@store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class BuyerSellersAuditsDetailsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.buyerSellersAuditsDetails.load),
        map((args) => {
          this.service.load(args);
        })
      );
    },
    {
      dispatch: false
    }
  );

  loadPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.buyerSellersAuditsDetails.loadPagination),
        map(({ pagination }) => {
          this.service.loadPage(pagination);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private service: services.BuyerSellersAuditsDetailsService
  ) {
    // not to do
  }
}

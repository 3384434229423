import { PurchaseItemsData } from '@interfaces';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { MonkeyEcxPage, MonkeyEcxPaginationData } from 'monkey-front-core';

export const featureKey = 'purchases-details-items';

export interface State extends EntityState<PurchaseItemsData> {
  control: {
    isLoading: boolean;
  };
  links?: MonkeyEcxPaginationData;
  page?: MonkeyEcxPage;
  error: any;
}

const entity = createEntityAdapter<PurchaseItemsData>({
  selectId: (item: PurchaseItemsData) => {
    return `${item.identifier}-${item.id}`;
  }
});

export const adapter: EntityAdapter<PurchaseItemsData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  links: null,
  page: null,
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.purchasesDetailsItems.updateAll, (state, action) => {
    return adapter.upsertMany([...action.data], {
      ...state
    });
  }),
  on(actions.purchasesDetailsItems.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.purchasesDetailsItems.updateLinks, (state, action) => {
    return {
      ...state,
      links: {
        ...state.links,
        ...action.data
      }
    };
  }),
  on(actions.purchasesDetailsItems.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...state.page,
        ...action.data
      }
    };
  }),
  on(actions.purchasesDetailsItems.clear, (state: any) => {
    const handled = adapter.removeAll(state);
    handled.page = null;
    handled.links = null;
    handled.control = { isLoading: false };

    return handled;
  })
);

export const { selectAll } = adapter.getSelectors();

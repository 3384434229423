import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { buyer as actions } from '@store/actions';
import { buyer as services } from '@store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchasesDetailsItemsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.purchasesDetailsItems.load),
        map((args) => {
          this.service.load(args);
        })
      );
    },
    {
      dispatch: false
    }
  );

  loadPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.purchasesDetailsItems.loadPagination),
        map((args) => {
          this.service.loadPage(args);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.PurchasesDetailsItemsService) {
    // not to do
  }
}

import { Injectable } from '@angular/core';
import { SponsorBuyerData } from '@interfaces';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { sponsor as selectors } from '@store/selectors';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCollectionStore,
  MonkeyEcxCommonsStoreBaseService,
  MonkeyEcxCoreService,
  MonkeyEcxLinksModel,
  MonkeyEcxPagination,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class SponsorBuyers extends MonkeyEcxCommonsStoreBaseService<
  SponsorBuyerData,
  MonkeyEcxCollectionStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertsService: MonkeyEcxAlertsService,
    public store: Store
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.buyers,
      selectors: selectors.buyers
    });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handledAction(data: SponsorBuyerData) {
    const { monkeyecxService } = this;

    try {
      const { href, type } = new MonkeyEcxLinksModel(data).getAction(
        data?.status === 'APPROVED' ? 'unapprove' : 'approve'
      );

      this.updateControl({ isLoading: true });

      const resp = await monkeyecxService[type.toLowerCase()]<SponsorBuyerData>(href).toPromise();

      this.alertsService.show({
        duration: 4000,
        message: 'UPDATE-MESSAGE',
        title: 'SUCCESS',
        type: 'success'
      });

      const buyer = <SponsorBuyerData>this.mappingData(resp);

      this.store.dispatch(actions.buyers.updateOne({ data: buyer }));
    } catch (e) {
      // not to do
    }
    this.updateControl({ isLoading: false });
  }

  public load(args: any) {
    const { url } = args;

    this.store.dispatch(actions.buyers.clear({ identifier: '' }));

    this.loadData(url);
  }

  public loadPage(pagination: MonkeyEcxPagination) {
    this.loadPageData(pagination);
  }

  public genericAction(url: SponsorBuyerData) {
    this.handledAction(url);
  }
}

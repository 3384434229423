import { PurchaseData, PurchaseDetailsWithMapData, PurchaseItemsData } from '@interfaces';
import { createSelector } from '@ngrx/store';
import { BankAccountData } from '@shared-interfaces';
import { buyer as reducers } from '@store/reducers';
import { buyer as selectors } from '@store/selectors';
import { MonkeyEcxCommonsSelectors, MonkeyEcxPage } from 'monkey-front-core';

export const { selectAll, selectState } = MonkeyEcxCommonsSelectors.getSelectors<PurchaseData>(
  reducers.fromPurchasesDetails
);

const mappingItems = (items: PurchaseItemsData[]) => {
  return items.map((_: PurchaseItemsData) => {
    return {
      ..._,
      taxes: _.tax + _.sponsorTax
    };
  });
};

export const selectOneByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAll,
    selectors.purchasesDetailsBankAccounts.selectAll,
    selectors.purchasesDetailsItems.selectAll,
    (
      entities: PurchaseData[],
      bankAccountsEntities: BankAccountData[],
      itemsEntities: PurchaseItemsData[]
    ): PurchaseDetailsWithMapData => {
      const data = entities.find((_: PurchaseData) => {
        return _.identifier === props.identifier;
      });

      return {
        data,
        items: mappingItems(itemsEntities),
        bankAccounts: bankAccountsEntities
      };
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectState,
    selectors.purchasesDetailsBankAccounts.selectState,
    selectors.purchasesDetailsItems.selectState,
    (state: any, bankAccountsState: any, itemsState: any): any => {
      const isLoading = state?.control?.isLoading || bankAccountsState?.control?.isLoading;

      const isLoadingInvoices = itemsState?.control?.isLoading;

      return { isLoading, isLoadingInvoices };
    }
  );
};

export const selectPage = (props: { identifier: string }) => {
  return createSelector(
    selectors.purchasesDetailsItems.selectState,
    (state: any): MonkeyEcxPage => {
      return state.page?.[props.identifier];
    }
  );
};

export const selectLinks = (props: { identifier: string }) => {
  return createSelector(selectors.purchasesDetailsItems.selectState, (state: any): any => {
    return state?.links?.[props.identifier];
  });
};

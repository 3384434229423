import { Component, Input } from '@angular/core';
import { MonkeyModalConfig } from 'monkey-style-guide';

@Component({
  selector: 'shared-confirm-action',
  styleUrls: ['./confirm-action.component.scss'],
  templateUrl: './confirm-action.component.html'
})
export class SharedConfirmActionComponent {
  @Input() config: MonkeyModalConfig;

  @Input() modalRef: any;

  _data: string;
}

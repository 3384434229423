import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { MonkeyEcxConfigService } from 'monkey-front-core';
import { first } from 'rxjs/operators';

@Directive({
  selector: '[monkeyecxMultifundingProgram]'
})
export class MonkeyEcxMultifundingProgramDirective {
  private ignoreMultifunding = false;

  @Input('ignoreMultifundingDirective') set ignoreMultifundingDirective(data: boolean | string) {
    this.ignoreMultifunding = data === true || data === 'true';
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private monkeyConfigService: MonkeyEcxConfigService
  ) {
    // not to do
  }

  async ngOnInit() {
    const { ignoreMultifunding, monkeyConfigService, renderer, el } = this;

    if (ignoreMultifunding !== false) return;

    const config = await monkeyConfigService
      .config()
      .pipe(
        first((val: any) => {
          return !!val && JSON.stringify(val) !== '{}';
        })
      )
      .toPromise();

    const multifunding = config?.program?.multifunding || false;

    if (!multifunding) {
      renderer.removeChild(el.nativeElement.parentNode, el.nativeElement);
    }
  }
}

import { BankAccountsData, PurchaseData } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';

export const load = createAction(
  '[Purchases Details BankAccounts] Load',
  props<{ data: PurchaseData }>()
);

export const { updateAll, updateControl, clear } =
  MonkeyEcxCommonsActions.getActions<BankAccountsData>('Purchases Details BankAccounts');

import { BuyerSellersAuditsData } from '@interfaces';
import { createSelector } from '@ngrx/store';
import { buyer as reducers } from '@store/reducers';
import { MonkeyEcxCommonsSelectors } from 'monkey-front-core';

export const {
  selectAll,
  selectPage,
  selectLinks,
  selectControl,
  linksHasDifference,
  selectByIdentifier
} = MonkeyEcxCommonsSelectors.getSelectors(reducers.fromBuyerSellersAuditsDetails);

export const selectAllGrouped = () => {
  return createSelector(selectAll, (entities: BuyerSellersAuditsData[]): any => {
    const data = entities.reduce((groups, item) => {
      const createdAt = new Date(item.createdAt).toISOString().split('T')[0];

      if (!groups[createdAt]) {
        groups[createdAt] = [];
      }
      groups[createdAt].push(item);

      return groups;
    }, {});

    return Object.keys(data).map((key) => {
      return {
        key,
        values: data[key]
      };
    });
  });
};

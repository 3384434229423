import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessStatusesEnum } from '@enums';
import { PurchaseData } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsStoreService,
  MonkeyEcxCoreService,
  MonkeyEcxEntityStore,
  MonkeyEcxLinksModel,
  MonkeyEcxRequestScheduleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class PurchasesDetailsService extends MonkeyEcxCommonsStoreService<
  PurchaseData,
  MonkeyEcxEntityStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertsService: MonkeyEcxAlertsService,
    private scheduleService: MonkeyEcxRequestScheduleService,
    public store: Store,
    private router: Router
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.purchasesDetails
    });
  }

  private buildScheduleRevert(response: MonkeyEcxLinksModel) {
    const { scheduleService } = this;

    const { href, type } = new MonkeyEcxLinksModel(response).getAction('process');

    scheduleService.setSchedule({
      method: type.toLowerCase(),
      url: href,
      data: {
        ...response
      },
      action: (data: any, sch: any) => {
        const { scheduleService } = this;
        const { status } = data;
        this.__schedule = sch;
        if (
          status === ProcessStatusesEnum.COMPLETED ||
          status === ProcessStatusesEnum.COMPLETED_WITH_ERROR
        ) {
          scheduleService.removeSchedule(sch);
          this.__schedule = null;

          const type = status === ProcessStatusesEnum.COMPLETED_WITH_ERROR ? 'error' : 'success';
          this.showAlert(type);

          this.router.navigate(['/app/buyer/purchases']);
        }
      },
      errorAction() {
        this.updateControl({ isLoading: false });
      }
    });
  }

  private buildScheduleUnapprove(data: PurchaseData) {
    const { scheduleService } = this;

    const { href, type } = new MonkeyEcxLinksModel(data).getAction('self');

    scheduleService.setSchedule({
      method: type.toLowerCase(),
      url: href,
      data,
      action: (data: any, sch: any) => {
        const { scheduleService } = this;
        const { status, identifier } = data;
        this.__schedule = sch;
        if (status === 'REFUSED') {
          scheduleService.removeSchedule(sch);
          this.__schedule = null;

          this.showAlert();
          this.loadDataAfterUnapprove(data, identifier);
        }
      },
      errorAction() {
        this.updateControl({ isLoading: false });
      }
    });
  }

  private handleFinishAction(response: any, data: PurchaseData, action: string) {
    if (action === 'unapprove') {
      this.buildScheduleUnapprove(data);
    } else if (action === 'revert') {
      this.buildScheduleRevert(response);
    } else {
      this.showAlert();
      this.updateControl({ isLoading: false });
    }
  }

  private showAlert(type: 'success' | 'error' = 'success') {
    setTimeout(() => {
      this.alertsService.show({
        duration: 4000,
        message: type === 'success' ? 'UPDATE-MESSAGE' : 'ERROR-OPERATION',
        title: type.toUpperCase(),
        type
      });
    }, 100);
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  protected async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<PurchaseData>(url).toPromise();

      this.update(data, identifier);

      this.store.dispatch(
        actions.purchasesDetailsBankAccounts.load({ data: { ...data, identifier } })
      );

      const itemsLink = new MonkeyEcxLinksModel(data).getAction('items')?.href;

      if (itemsLink) {
        this.store.dispatch(actions.purchasesDetailsItems.load({ url: itemsLink, identifier }));
      }
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  private async loadDataAfterUnapprove(data: PurchaseData, identifier: string) {
    this.update(data, identifier);

    const itemsLink = new MonkeyEcxLinksModel(data).getAction('items')?.href;

    if (itemsLink) {
      this.store.dispatch(actions.purchasesDetailsItems.load({ url: itemsLink, identifier }));
    }

    this.updateControl({ isLoading: false });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleGenericAction(details: PurchaseData, action: string) {
    const { monkeyecxService } = this;
    const { href, type } = new MonkeyEcxLinksModel(details).getAction(action);
    if (!href) return;

    try {
      this.updateControl({ isLoading: true });

      const response = await monkeyecxService[type.toLowerCase()]<PurchaseData>(href).toPromise();

      this.handleFinishAction(response, details, action);
    } catch (e) {
      this.updateControl({ isLoading: false });
    }
  }

  public load({ url, identifier }) {
    this.store.dispatch(actions.purchasesDetails.clear(identifier));

    this.loadData(url, identifier);
  }

  public genericAction(data: PurchaseData, action: string) {
    this.handleGenericAction(data, action);
  }
}

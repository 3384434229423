import { SponsorBuyerData } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { MonkeyEcxPage, MonkeyEcxPaginationData } from 'monkey-front-core';

export const featureKey = 'sponsor-buyers';

export interface State extends EntityState<SponsorBuyerData> {
  control: {
    isLoading: boolean;
  };
  links?: MonkeyEcxPaginationData;
  page?: MonkeyEcxPage;
  error: any;
}

const entity = createEntityAdapter<SponsorBuyerData>({
  selectId: (item: SponsorBuyerData) => {
    return item.id;
  }
});

export const adapter: EntityAdapter<SponsorBuyerData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  links: null,
  page: null,
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.buyers.updateAll, (state, action) => {
    return adapter.upsertMany([...action.data], {
      ...state
    });
  }),
  on(actions.buyers.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data, id: action.data?.id },
      {
        ...state
      }
    );
  }),
  on(actions.buyers.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.buyers.updateLinks, (state, action) => {
    return {
      ...state,
      links: {
        ...action.data
      }
    };
  }),
  on(actions.buyers.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...state.page,
        ...action.data
      }
    };
  }),
  on(actions.buyers.clear, (state: any) => {
    const handled = adapter.removeAll(state);
    handled.page = null;
    handled.links = null;
    handled.control = { isLoading: false };

    return handled;
  })
);

export const { selectAll } = adapter.getSelectors();

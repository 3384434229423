import { PurchaseData } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';

export const api = {
  genericAction: createAction(
    '[Purchases Details] Generic Action',
    props<{ data: PurchaseData; action: string }>()
  )
};

export const { load, updateControl, updateOne, clear } =
  MonkeyEcxCommonsActions.getActions<PurchaseData>('Purchases Details');

<monkey-modal [config]="config" [modalRef]="modalRef">
  <div class="alert-body">
    <div
      class="align-items-center d-flex icon-bg justify-content-center"
      [ngClass]="config?.data?.type"
    >
      <i class="mk-i font-size-xxl" [ngClass]="'mk-i-' + config?.data?.icon"></i>
    </div>
    <span class="font-size-md font-weight-medium">{{ config?.data?.title | translate }}</span>
    <span class="title-muted font-size-xs font-weight-regular">
      {{ config?.data?.description | translate }}
    </span>
  </div>
</monkey-modal>

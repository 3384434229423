import { SponsorBuyerData } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';

export const genericAction = createAction(
  '[Sponsor Buyers] Action',
  props<{ data: SponsorBuyerData }>()
);

export const {
  load,
  loadPagination,
  updateAll,
  updateOne,
  updateControl,
  clear,
  updateLinks,
  updatePage
} = MonkeyEcxCommonsActions.getActions<SponsorBuyerData>('Sponsor Buyers');

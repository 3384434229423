import { Injectable } from '@angular/core';
import { BuyerSellersAuditsData } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { buyer as selectors } from '@store/selectors';
import {
  MonkeyEcxCollectionStore,
  MonkeyEcxCommonsStoreBaseService,
  MonkeyEcxLinksModel,
  MonkeyEcxPagination,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class BuyerSellersAuditsDetailsService extends MonkeyEcxCommonsStoreBaseService<
  BuyerSellersAuditsData[],
  MonkeyEcxCollectionStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    store: Store
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.buyerSellersAuditsDetails,
      selectors: selectors.buyerSellersAuditsDetails
    });
  }

  override mappingData(args: BuyerSellersAuditsData[]): BuyerSellersAuditsData[] {
    return args.map((item: BuyerSellersAuditsData) => {
      let id = item.createdAt;

      const { href } = new MonkeyEcxLinksModel(item)?.getAction('self') || {
        href: ''
      };

      if (href) {
        id = href.split('/').pop() || '';
      }

      let { detail } = item;
      if (detail) {
        detail = detail.replace(/<span>/g, '<span class="mecx-timeline-title-data">');
      }

      return {
        ...item,
        id,
        detail
      };
    });
  }

  public load(args: any) {
    const { url, identifier } = args;

    this.store.dispatch(actions.buyerSellersAuditsDetails.clear(identifier));

    this.loadData(url, identifier);
  }

  public loadPage(pagination: MonkeyEcxPagination) {
    this.loadPageData(pagination);
  }
}

import { NgModule } from '@angular/core';
import { BankAccountSecurityDirective } from './bank-account-security-directive';
import { MonkeyEcxEnableFeatureByProgramDirective } from './enable-feature-by-program-directive';
import { FeatureByCountryDirective } from './feature-by-country-directive';
import { MonkeyEcxMultifundingProgramDirective } from './multifunding-program-directive';
import { SponsorTaxSecurityDirective } from './sponsor-tax-security-directive';

@NgModule({
  declarations: [
    BankAccountSecurityDirective,
    SponsorTaxSecurityDirective,
    FeatureByCountryDirective,
    MonkeyEcxEnableFeatureByProgramDirective,
    MonkeyEcxMultifundingProgramDirective
  ],
  exports: [
    BankAccountSecurityDirective,
    SponsorTaxSecurityDirective,
    FeatureByCountryDirective,
    MonkeyEcxEnableFeatureByProgramDirective,
    MonkeyEcxMultifundingProgramDirective
  ]
})
export class DirectivesModule {}

import { BuyerSellersAuditsData } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';

export const featureKey = 'buyer-sellers-audits-details';

export interface State extends EntityState<BuyerSellersAuditsData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

const entity = createEntityAdapter<BuyerSellersAuditsData>({
  selectId: (item: BuyerSellersAuditsData) => {
    return `${item.createdAt}-${item.step}`;
  }
});

export const adapter: EntityAdapter<BuyerSellersAuditsData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.buyerSellersAuditsDetails.clear, (state) => {
    return adapter.removeAll(state);
  }),
  on(actions.buyerSellersAuditsDetails.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.buyerSellersAuditsDetails.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.buyerSellersAuditsDetails.updateLinks, (state, action) => {
    return {
      ...state,
      links: {
        ...action.data
      }
    };
  }),
  on(actions.buyerSellersAuditsDetails.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...action.data
      }
    };
  })
);

export const { selectAll } = adapter.getSelectors();

import { PurchaseData } from '@interfaces';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';

export const featureKey = 'purchases-details';

export interface State extends EntityState<PurchaseData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

const entity = createEntityAdapter<PurchaseData>({
  selectId: (item: PurchaseData) => {
    return `${item.identifier}-${item.id}`;
  }
});

export const adapter: EntityAdapter<PurchaseData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.purchasesDetails.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data },
      {
        ...state
      }
    );
  }),
  on(actions.purchasesDetails.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.purchasesDetails.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectAll } = adapter.getSelectors();

import { RecipientError } from '@shared-interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { credit as actions } from '@shared-store/actions';
import { MonkeyEcxLinks, MonkeyEcxPage } from 'monkey-front-core';

export const featureKey = 'mecx-shared-mail-configuration-upload-issues';

export interface State extends EntityState<RecipientError> {
  control: {
    isLoading: boolean;
  };
  error: any;
  links?: MonkeyEcxLinks;
  page?: MonkeyEcxPage;
}

export const adapter: EntityAdapter<RecipientError> = createEntityAdapter<RecipientError>({
  selectId: (item: RecipientError) => {
    return item.detail;
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(actions.mailConfigurationUploadIssues.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(actions.mailConfigurationUploadIssues.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.mailConfigurationUploadIssues.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.mailConfigurationUploadIssues.updateLinks, (state, action) => {
    return {
      ...state,
      links: action.data
    };
  }),
  on(actions.mailConfigurationUploadIssues.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...state.page,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { MonkeyEcxModel, MonkeyEcxUtils } from 'monkey-front-core';

interface Link {
  href: string;
  type: string;
}

interface Links {
  documents: Link;
  payables: Link;
  self: Link;
  sponsor: Link;
  supplier: Link;
  'upload-status': Link;
}

export class PayableModel extends MonkeyEcxModel {
  id: string;

  identifier: string;

  assetType: string;

  buyerGovernmentId: string;

  buyerName: string;

  buyerReceivementAccount: string;

  buyerReceivementAccountDigit: string;

  buyerReceivementAgency: string;

  buyerReceivementBank: string;

  companyName: string;

  createUserId: string;

  createUserIp: string;

  createdAt: Date;

  effectivePaymentDate: Date;

  externalId: string;

  governmentId: string;

  installment: number;

  invoiceDate: Date;

  invoiceKey: string;

  invoiceNumber: string;

  lastUserId: string;

  lastUserIp: string;

  paymentDate: Date;

  paymentValue: number;

  purchasedTax: number;

  realPaymentDate: Date;

  sellerReceivementValue: number;

  sponsorId: string;

  sponsorReceivementValue: number;

  sponsorTax: number;

  status: string;

  supplierGovernmentId: string;

  supplierName: string;

  totalInstallment: number;

  updatedAt: Date;

  billLine: string;

  billUrl: string;

  billRegisteredAt: Date;

  _links: Links;

  constructor(data?: any) {
    super();
    this.assetType = data?.assetType;
    this.buyerGovernmentId = data?.buyerGovernmentId;
    this.buyerName = data?.buyerName;
    this.buyerReceivementAccount = data?.buyerReceivementAccount;
    this.buyerReceivementAccountDigit = data?.buyerReceivementAccountDigit;
    this.buyerReceivementAgency = data?.buyerReceivementAgency;
    this.buyerReceivementBank = data?.buyerReceivementBank;
    this.companyName = data?.companyName;
    this.createUserId = data?.createUserId;
    this.createUserIp = data?.createUserIp;
    this.createdAt = data?.createdAt;
    this.effectivePaymentDate = data?.effectivePaymentDate;
    this.externalId = data?.externalId;
    this.governmentId = data?.governmentId;
    this.installment = data?.installment;
    this.invoiceDate = data?.invoiceDate;
    this.invoiceKey = data?.invoiceKey;
    this.invoiceNumber = data?.invoiceNumber;
    this.lastUserId = data?.lastUserId;
    this.lastUserIp = data?.lastUserIp;
    this.paymentDate = data?.paymentDate;
    this.paymentValue = data?.paymentValue;
    this.purchasedTax = data?.purchasedTax;
    this.realPaymentDate = data?.realPaymentDate;
    this.sellerReceivementValue = data?.sellerReceivementValue;
    this.sponsorId = data?.sponsorId;
    this.sponsorReceivementValue = data?.sponsorReceivementValue;
    this.sponsorTax = data?.sponsorTax;
    this.status = data?.status;
    this.supplierGovernmentId = data?.supplierGovernmentId;
    this.supplierName = data?.supplierName;
    this.totalInstallment = data?.totalInstallment;
    this.updatedAt = data?.updatedAt;
    this.billLine = data?.billLine;
    this.billUrl = data?.billUrl;
    this.billRegisteredAt = data?.billRegisteredAt;
    this._links = data?._links;
    this.identifier = data?.identifier;

    const actionId: any = this.getAction('self');
    this.id = actionId?.href?.split('/')?.pop();
  }

  public getInitials() {
    const { supplierName } = this;
    const first = MonkeyEcxUtils.cutFirstLastName(supplierName, 'first');
    const second = MonkeyEcxUtils.cutFirstLastName(supplierName, 'last');
    return first && second
      ? `${first.substring(0, 1)} ${second.substring(0, 1)}`
      : `${supplierName.substring(0, 2)}`;
  }
}

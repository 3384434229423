import { Injectable } from '@angular/core';
import { BuyerSellersData } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { Utils } from 'app/core/utils';
import * as FileSaver from 'file-saver';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsStoreService,
  MonkeyEcxCoreService,
  MonkeyEcxEntityStore,
  MonkeyEcxLinksModel,
  MonkeyEcxService,
  MonkeyEcxServiceDownload,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class BuyerSellersDetailsService extends MonkeyEcxCommonsStoreService<
  BuyerSellersData,
  MonkeyEcxEntityStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertsService: MonkeyEcxAlertsService,
    public store: Store
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.buyerSellersDetails
    });
  }

  private handleFinishAction(data: BuyerSellersData, identifier: string) {
    this.alertsService.show({
      duration: 4000,
      message: 'UPDATE-MESSAGE',
      title: 'SUCCESS',
      type: 'success'
    });

    this.update(data, identifier);
  }

  protected mappingData(data: BuyerSellersData, identifier: string) {
    const actionId: string = new MonkeyEcxLinksModel(data)?.getAction('self')?.href;
    const id = actionId?.split('/')?.pop();
    const isAbleToExport = !!new MonkeyEcxLinksModel(data)?.getAction('documents')?.href;

    return {
      ...data,
      identifier,
      id,
      isAbleToExport,
      governmentIdType: Utils.getDocumentType(data?.governmentId)
    };
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  protected async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<BuyerSellersData>(url).toPromise();

      this.loadAudits(data, identifier);
      this.update(data, identifier);

      const bankAccountsLink = new MonkeyEcxLinksModel(data).getAction('bank-accounts')?.href;

      const suppliersLink = new MonkeyEcxLinksModel(data).getAction('suppliers')?.href;

      if (bankAccountsLink) {
        this.store.dispatch(
          actions.buyerSellersDetailsBankAccounts.load({ url: bankAccountsLink, identifier })
        );
      }

      if (suppliersLink) {
        const { governmentId } = data;

        const search = `?search=supplierGovernmentId:${governmentId}&page=0&size=5`;
        const linkWithSearch = `${suppliersLink}${search}`;

        this.store.dispatch(
          actions.buyerSellersDetailsSponsors.load({ url: linkWithSearch, identifier })
        );
      }
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleUpdateStatus(details: BuyerSellersData, statusType: string) {
    const { monkeyecxService } = this;
    const { href, type } = new MonkeyEcxLinksModel(details).getAction(statusType);
    if (!href) return;

    try {
      this.updateControl({ isLoadingStatus: true });

      const data = await monkeyecxService[type.toLowerCase()]<BuyerSellersData>(href).toPromise();

      this.handleFinishAction(data, details?.identifier);
    } catch (e) {
      // not to do
    }
    this.updateControl({ isLoadingStatus: false });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleUpdateRisk(details: BuyerSellersData, risk: string) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoadingRisk: true });

    try {
      const { href, type } = new MonkeyEcxLinksModel(details).getAction('risk');
      if (!href) return;

      const data = await monkeyecxService[type.toLowerCase()]<BuyerSellersData>(href, {
        risk
      }).toPromise();

      this.handleFinishAction(data, details?.identifier);
    } catch (e) {
      // not to do
    }
    this.updateControl({ isLoadingRisk: false });
  }

  @MonkeyEcxServiceDownload('SERVICES.DOWNLOAD.FILE', '', {
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleDownload(data: BuyerSellersData) {
    this.updateControl({ isLoadingDownload: true });

    try {
      const { href } = new MonkeyEcxLinksModel(data).getAction('documents');
      const file = await this.monkeyecxService
        .downloadOb<any>(href, this.getHTTPHeaderApplicationPDF())
        .toPromise();

      FileSaver.saveAs(file?.blob, file?.filename);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoadingDownload: false });
  }

  private loadAudits(data: BuyerSellersData, identifier: string) {
    const { href } = new MonkeyEcxLinksModel(data).getAction('audits');
    if (!href) {
      return;
    }
    this.store.dispatch(
      actions.buyerSellersAuditsDetails.load({
        url: href,
        identifier
      })
    );
  }

  public load({ url, identifier }) {
    this.store.dispatch(actions.buyerSellersDetails.clear(identifier));

    this.loadData(url, identifier);
  }

  public updateStatus(data: BuyerSellersData, statusType: string) {
    this.handleUpdateStatus(data, statusType);
  }

  public updateRisk(data: BuyerSellersData, risk: string) {
    this.handleUpdateRisk(data, risk);
  }

  public download(data: BuyerSellersData) {
    this.handleDownload(data);
  }
}

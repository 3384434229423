<ng-container *ngIf="!item.hidden">
  <div
    monkeyecxSecurity
    [securityRoles]="item.role"
    class="nav-line"
    monkeyecxFeature
    [featureName]="item.featureToggle"
    monkeyecxFeatureByProgram
    [feature]="item.featureByProgram"
    monkeyecxMultifundingProgram
    [ignoreMultifundingDirective]="!item.isMultifunding ?? true"
  >
    <!-- item.url -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && !item.externalUrl && !item.function"
      [routerLink]="[item.url]"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && item.externalUrl && !item.function"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="!item.url && item.function"
      (click)="item.function()"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && !item.externalUrl && item.function"
      (click)="item.function()"
      [routerLink]="[item.url]"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && item.externalUrl && item.function"
      (click)="item.function()"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
      <monkey-button
        class="nav-link-title"
        color="theme"
        [icon]="item.icon"
        tooltipPosition="right"
        monkeyecxTooltip
        [label]="(item.translate | translate : _configBootstrap) || item.title"
        [type]="
          this.router.isActive(item.url, false)
            ? item?.main
              ? 'primary'
              : 'nav-active'
            : item?.main
            ? 'primary'
            : 'nav'
        "
        [tooltipText]="
          (item?.translateDescription | translate : _configBootstrap) || item?.description
        "
        [tooltipTitle]="(item.translate | translate : _configBootstrap) || item.title"
        *ngIf="_folded; else normalButton"
      >
        <monkey-badge [count]="item?.badge" [pulseOn]="true" *ngIf="item?.badge"></monkey-badge>
      </monkey-button>
      <ng-template #normalButton>
        <monkey-button
          class="nav-link-title"
          color="theme"
          [icon]="item.icon"
          [label]="(item.translate | translate : _configBootstrap) || item.title"
          [type]="
            this.router.isActive(item.url, false)
              ? item?.main
                ? 'primary'
                : 'nav-active'
              : item?.main
              ? 'primary'
              : 'nav'
          "
        >
          <monkey-badge [count]="item?.badge" [pulseOn]="true" *ngIf="item?.badge"></monkey-badge>
        </monkey-button>
      </ng-template>
    </ng-template>
  </div>
</ng-container>
